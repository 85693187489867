import CoursesTable from '../../Course/CoursesTable'
import { Button, Col, Form, Modal, ModalBody } from 'reactstrap'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormikProps, useFormik } from 'formik'
import { ICoursesData, Step2Filters } from '../AssignCourseModal/types'
import {
  COURSE_FILTER_FORMATS,
  CoursesTabs,
  GetOnlineCoursesDTO,
  ONLINE_COURSE_STATUS,
  OnlineCourseItem,
  SelectedCourse,
} from '../../../sharedTypes'
import _ from 'lodash-es'
import { getOnlineCourses } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import { errorToastOptions } from '../../../helpers/toast_helper'
import Filters from './Filters'
import { CoursesTableProps } from '../../Course/CoursesTable/types'
import {
  FormValues,
  PackageCoursesTableProps,
  SelectCoursesModalProps,
  SelectedPackageCourse,
} from '../../../pages/Courses/Packages/types'

const initialValues: FormValues = {
  courses: [],
}

const SelectCoursesModal = ({
  isOpen,
  onClose,
  handleSelect,
  packageCourses,
  filteredFormats = [],
}: SelectCoursesModalProps) => {
  const onSubmit = () => {
    const coursesData = form.values.courses.map(item => ({
      id: item.course.id,
      format: item.format,
    }))

    const hasFormatError = !_.every(
      coursesData,
      courseItem =>
        _.has(courseItem, 'format') &&
        courseItem.format !== undefined &&
        courseItem.format !== null,
    )
    if (hasFormatError) {
      toast('Select format of the course', errorToastOptions)
      form.setSubmitting(false)
      return
    }

    handleSelect(coursesData)
  }

  const form = useFormik<FormValues>({
    enableReinitialize: false,
    initialValues,
    onSubmit,
  })
  const formikFormRef = useRef<FormikProps<FormValues>>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [courseFilters, setCourseFilters] = useState<Step2Filters>({
    activeTab: CoursesTabs.COURSES,
    limit: 10,
  })
  const [itemsToEnroll, setItemsToEnroll] = useState<ICoursesData>({
    page: 0,
    data: [],
  })

  useEffect(() => {
    form.resetForm()
  }, [isOpen, formikFormRef])

  useEffect(() => {
    if (itemsToEnroll.data.length === 0) {
      setIsLoading(true)
      fetchItemsToEnroll()
    }
  }, [courseFilters.activeTab])

  const data = useMemo(() => {
    const values = form.values.courses
    const selected = values.map(item => {
      return 'course' in item ? item.course : item
    })
    const _data = itemsToEnroll.data as OnlineCourseItem[]

    const selectedNotInList = _.differenceBy(selected, _data, 'id') // not in the list

    return [..._data, ...selectedNotInList] as CoursesTableProps['data']
  }, [courseFilters.activeTab, itemsToEnroll, form.values])

  const fetchItemsToEnroll = (page?: number) => {
    const query: GetOnlineCoursesDTO.Request = {
      page: page || undefined,
      key: courseFilters.key,
      statuses: [ONLINE_COURSE_STATUS.PUBLISHED],
    }

    if (!_.isEmpty(courseFilters.formats)) {
      query.formats = courseFilters.formats
    }

    if (!_.isEmpty(filteredFormats)) {
      query.formats = filteredFormats
    }

    if (!_.isEmpty(courseFilters.types)) {
      query.types = courseFilters.types
    }

    getOnlineCourses(query)
      .then(res => ({
        data: res.courses,
        page: res.page,
      }))
      .then(({ page, data }) => {
        setItemsToEnroll(prev => ({
          ...prev,
          data: page > 1 ? [...itemsToEnroll.data, ...data] : data,
          page,
        }))
      })
      .catch(() => {
        toast('Something went wrong', errorToastOptions)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    fetchItemsToEnroll()
  }, [courseFilters])

  useEffect(() => {
    const coursesData: SelectedPackageCourse[] = itemsToEnroll.data
      .filter(course =>
        packageCourses.some(
          packageCourse => packageCourse.course.id === course.id,
        ),
      )
      .map(course => ({
        course,
        format: packageCourses.find(
          packageCourse => packageCourse.course.id === course.id,
        )?.format,
      }))

    if (!form.values.courses.length) {
      form.setFieldValue('courses', coursesData)
    }
  }, [itemsToEnroll.data])

  const onSelect: PackageCoursesTableProps['setSelected'] = (
    element,
    format,
  ): void => {
    const newValues = [...form.values.courses]
    const index = newValues.findIndex(item => item.course.id === element.id)
    //already selected (need uncheck)
    if (index >= 0) {
      if (format) {
        newValues[index].format = format
        form.setFieldValue('courses', newValues)

        return
      }
      newValues.splice(index, 1)
      form.setFieldValue('courses', newValues)

      return
    }
    if (format) {
      newValues.push(
        'available_formats' in element ? { course: element, format } : element,
      )
    } else {
      newValues.push(
        'available_formats' in element
          ? {
              course: element,
              format:
                element.available_formats.length === 1
                  ? element.available_formats[0]
                  : undefined,
            }
          : element,
      )
    }
    form.setFieldValue('courses', newValues)
  }

  const filterOptions = useMemo(() => {
    if (filteredFormats.length) {
      return [
        {
          value: COURSE_FILTER_FORMATS.IN_PERSON,
          label: COURSE_FILTER_FORMATS.IN_PERSON,
        },
      ]
    }
  }, [filteredFormats])

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered size={'xl'}>
      <ModalBody className='p-0'>
        <div className='p-3 border-bottom'>
          <div className='hstack w-100 flex-1 align-items-center justify-content-end gap-5'>
            <h5 className='flex-1 fw-light'>Select Course</h5>
            <div
              className='flex-1 position-relative align-items-center justify-content-end'
              style={{ maxWidth: 250 }}
            >
              <div
                className='hstack justify-content-between'
                style={{ zIndex: 100 }}
              ></div>
            </div>
            <i
              className='ri-close-line fs-24 cursor-pointer'
              onClick={onClose}
            ></i>
          </div>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <>
            <Filters
              filters={courseFilters}
              onFiltersChange={setCourseFilters}
              filterOptions={filterOptions}
            />
            <CoursesTable
              selected={form.values as SelectedCourse}
              setSelected={onSelect as any}
              isLoading={isLoading}
              dataType={courseFilters.activeTab}
              data={data}
              searchKey={courseFilters.key}
            />
            <div className='p-3 row g-3'>
              <Col lg={12}>
                <div className='hstack gap-2 justify-content-end'>
                  <Button
                    color='ghost-primary'
                    className='text-light-purple'
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='align-middle'
                    color='primary'
                    type='submit'
                    disabled={!form.dirty || form.isSubmitting}
                  >
                    Select{' '}
                    {form.values.courses.length > 0 &&
                      `(${form.values.courses.length})`}
                  </Button>
                </div>
              </Col>
            </div>
          </>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default SelectCoursesModal

import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import Highlighter from 'react-highlight-words'
import {
  OnlineTagsSortBy,
  GetOnlineTagsDTO,
  OnlineTagItem,
  CoursesPermissions,
} from '../../../sharedTypes'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { OrderType } from '../../../sharedTypes'
import { Pagination } from '../../../Components/Common/Pagination'
import { successToastOptions } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import {
  deleteOnlineTags,
  getOnlineTags,
  getOnlineTagsEntries,
  postOnlineTags,
  putOnlineTags,
} from '../../../helpers/api_helper'
import { FormikHelpers } from 'formik'
import { errorToastOptions } from '../../../helpers/toast_helper'
import {
  OnlineTagInitialValues,
  OnlineTagModalSubmitedValues,
} from '../../../sharedTypes'
import OnlineTagModal from './OnlineTagModal'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'

const Columns = [
  {
    name: 'Keyword Name',
    sortBy: OnlineTagsSortBy.NAME,
    style: { width: '93%' },
  },
  {
    name: 'Actions',
    style: { width: '7%' },
  },
]

const OnlineTags = () => {
  document.title = 'Course Keywords | Mastered - Admin & Dashboard'

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<GetOnlineTagsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    online_tags: [],
  })

  const [query, setQuery] = useState<GetOnlineTagsDTO.Request>({
    page: 1,
    limit: 10,
    key: null,
    sortBy: OnlineTagsSortBy.NAME,
    orderBy: OrderType.ASC,
    permission: CoursesPermissions.VIEW_COURSE_KEYWORDS,
  })
  const [globalSearch, setSearch] = useState<string>('')
  const [createModal, setCreateModal] = useState<OnlineTagInitialValues | null>(
    null,
  )
  const [editModal, setEditModal] = useState<OnlineTagInitialValues | null>(
    null,
  )

  const [deleteId, setDeleteId] = useState<null | number>()
  const [courseEntries, setCourseEntries] = useState<number>(0)

  const handleSort = useCallback((column: OnlineTagsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const handleDelete = useCallback((item: OnlineTagItem) => {
    if (!item.coursesCount) {
      setDeleteId(item.id)
      getOnlineTagsEntries(item.id).then(res => {
        setCourseEntries(res)
      })
    }
  }, [])

  const onDelete = useCallback(async () => {
    try {
      if (deleteId) {
        await deleteOnlineTags(deleteId)
      }
      setDeleteId(null)
      toast('Successfully deleted', successToastOptions)
      setQuery(prev => ({ ...prev, page: 1 }))
    } catch (e) {}
  }, [data.page, deleteId])

  const onCreate = useCallback(
    async (
      values: OnlineTagModalSubmitedValues,
      form: FormikHelpers<OnlineTagModalSubmitedValues>,
    ) => {
      try {
        await postOnlineTags(values)
        form.resetForm()
        toast('Successfully added', successToastOptions)
        setQuery(prev => ({ ...prev, page: 1 }))
        setCreateModal(null)
      } catch (e: any) {
        toast(e.response.data.message, errorToastOptions)
      }
    },
    [data.page],
  )

  const onEdit = useCallback(
    async (
      { id, ...rest }: OnlineTagModalSubmitedValues,
      form: FormikHelpers<OnlineTagModalSubmitedValues>,
    ) => {
      try {
        form.setSubmitting(true)
        await putOnlineTags(id as number, rest)
        toast('Successfully updated', successToastOptions)
        form.setSubmitting(false)
        setQuery(prev => ({ ...prev, page: 1 }))
        setEditModal(null)
      } catch (e) {
        form.setSubmitting(false)
      }
    },
    [],
  )

  useEffect(() => {
    setIsLoading(true)
    getOnlineTags(query)
      .then(response => {
        setData(response)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  return (
    <React.Fragment>
      <div className='page-content'>
        <DeleteConfirmation
          isOpen={!!deleteId}
          title='Delete Keyword'
          message={`This keyword is currently assigned to ${courseEntries} courses.
          You are about to permanently delete this keyword from all courses it is assigned to.`}
          onDelete={onDelete}
          onClose={() => {
            setDeleteId(null)
          }}
        />
        <OnlineTagModal
          isOpen={!!createModal || !!editModal}
          title={editModal ? 'Edit Keyword' : 'Add New Keyword'}
          initialValues={editModal || createModal}
          onClose={() => {
            setEditModal(null)
            setCreateModal(null)
          }}
          onSubmit={editModal ? onEdit : onCreate}
        />
        <Container fluid>
          <BreadCrumb
            title='Course Keywords'
            items={[
              {
                title: 'Courses',
                linkTo: '/',
              },
              {
                title: 'Course Keywords',
                active: true,
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                        setSearch(key)
                      }}
                      value={globalSearch}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        color={'primary'}
                        onClick={() => {
                          setCreateModal({
                            name: '',
                          })
                        }}
                        className='btn btn-primary align-middle'
                      >
                        <i className='ri-add-line me-1 fs-16'></i>Add New
                        Keyword
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  {' '}
                  <div className='table-card'>
                    <Table className='align-middle table-nowrap mb-0'>
                      <thead className='table-light'>
                        <tr className='text-muted fs-14'>
                          {Columns.map(column => (
                            <th
                              scope='col'
                              className='align-middle'
                              style={column.style}
                              key={column.name}
                            >
                              {column.name}
                              {!!column.sortBy && (
                                <ColumnSortIcon<OnlineTagsSortBy>
                                  sortOrder={query.orderBy}
                                  sortedColumn={query.sortBy}
                                  column={column.sortBy}
                                  handleSort={handleSort}
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.online_tags.map((item: OnlineTagItem, i) => (
                          <tr key={i} className='fs-14 fw-light'>
                            <td>
                              <Highlighter
                                highlightClassName='text-highlight'
                                searchWords={[globalSearch || '']}
                                highlightTag={'span'}
                                autoEscape={true}
                                textToHighlight={item.name}
                              />
                            </td>
                            <td>
                              <span className='d-flex gap-2'>
                                <i
                                  onClick={() => {
                                    setEditModal(item)
                                  }}
                                  className='bx bx-edit-alt cursor-pointer'
                                ></i>
                                <i
                                  onClick={() => handleDelete(item)}
                                  className={`bx bx-trash text-${
                                    item.coursesCount
                                      ? 'muted'
                                      : 'danger cursor-pointer'
                                  }`}
                                ></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className='mx-3 mt-3'>
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OnlineTags

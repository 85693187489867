import React, { useCallback, useState } from 'react'
import { QuestionList, CreateQuestion } from './index'
import {
  QuestionableTypes,
  TLanguage,
  IQuestionForm,
  TextAnswer,
  ImageAnswer,
  QuestionTypes,
  PostOnlineCourseQuestionDTO,
} from '../../../sharedTypes'
import { pick, sortBy } from 'lodash'
import {
  postOnlineCourseQuestion,
  putOnlineCourseQuestion,
  getOnlineCourseQuestionGroup,
} from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../helpers/toast_helper'
import { IUseQuestions } from '../../../hooks/course/useQuestions'

enum QuestionsModuleMode {
  LIST = 'list',
  CREATE = 'create',
  EDIT = 'edit',
}

interface IQuestionsModule extends IUseQuestions {
  courseId: number
  questionableType: QuestionableTypes
  languages: TLanguage[]
  viewOnly?: boolean
  isPublishing?: boolean
  error?: string | null
}

export const QuestionsModule = ({
  courseId,
  questionableType,
  languages,
  viewOnly,
  isPublishing,
  error,
  ...rest
}: IQuestionsModule) => {
  const [loading, setLoading] = useState(false)
  const [editabLeQuestion, setEditabLeQuestion] =
    useState<null | IQuestionForm>(null)
  const [mode, setMode] = useState(QuestionsModuleMode.LIST)

  const onFetchGroup = useCallback(
    async (groupId: string) => {
      const data = await getOnlineCourseQuestionGroup(courseId, groupId)
      if (data[0].type === QuestionTypes.TRUE_FALSE) {
        setEditabLeQuestion({
          groupId: data[0].groupId,
          type: data[0].type,
          questions: sortBy(data, 'languageId').map(q => {
            return {
              question: q.question,
              languageId: q.languageId,
              answer: q.answers as any,
            }
          }),
        })
      } else {
        setEditabLeQuestion({
          groupId: data[0].groupId,
          type: data[0].type,
          questions: sortBy(data, 'languageId').map(q => {
            return {
              question: q.question,
              languageId: q.languageId,
              answers: q.answers as any,
            }
          }),
        })
      }
      setMode(QuestionsModuleMode.EDIT)
    },
    [courseId, languages],
  )

  const onSubmit = useCallback(
    async ({ type, questions, groupId }: IQuestionForm) => {
      let request: PostOnlineCourseQuestionDTO.Request

      if (type === QuestionTypes.SHORT_ANSWER) {
        request = {
          type,
          questionableType,
          questions: questions.map(q => ({
            ...q,
          })),
        }
      } else if (type === QuestionTypes.MULTIPLE_CHOICE) {
        request = {
          type,
          questionableType,
          questions: questions.map(q => ({
            ...q,
            answers: (q.answers as TextAnswer[]).map(a =>
              pick(a, ['answer', 'correct']),
            ),
          })),
        }
      } else if (type === QuestionTypes.IMAGE_CHOICE) {
        request = {
          type,
          questionableType,
          questions: questions.map(q => ({
            ...q,
            answers: (q.answers as ImageAnswer[]).map(a =>
              pick(a, ['attachmentId', 'correct']),
            ),
          })),
        }
      } else {
        request = {
          type,
          questionableType,
          questions: questions.map(q => ({
            ...q,
            answer: q.answer as { correct: boolean },
          })),
        }
      }

      try {
        if (groupId) {
          await putOnlineCourseQuestion(courseId, groupId, request)
          toast(
            `Successfully created ${type} type question`,
            successToastOptions,
          )
        } else {
          await postOnlineCourseQuestion(courseId, request)
          toast(
            `Successfully created ${type} type question`,
            successToastOptions,
          )
        }
        rest.setQuery(prev => ({ ...prev, page: 1 }))

        setMode(QuestionsModuleMode.LIST)
      } catch (e) {}
    },
    [questionableType],
  )

  const renderErrorBadge = useCallback(
    () =>
      error ? (
        <div className='d-flex justify-content-center mb-3'>
          <span className='badge badge-soft-danger fs-12 fw-normal'>
            {error}
          </span>
        </div>
      ) : null,
    [error],
  )

  if ([QuestionsModuleMode.CREATE, QuestionsModuleMode.EDIT].includes(mode)) {
    return (
      <>
        {renderErrorBadge()}
        <CreateQuestion
          initialValues={editabLeQuestion}
          languages={sortBy(languages, 'id')}
          onCancel={() => {
            setEditabLeQuestion(null)
            setMode(QuestionsModuleMode.LIST)
          }}
          loading={loading}
          onSubmit={onSubmit}
        />
      </>
    )
  } else if (mode === QuestionsModuleMode.LIST) {
    return (
      <>
        {renderErrorBadge()}
        <QuestionList
          viewOnly={viewOnly}
          isPublishing={isPublishing}
          {...rest}
          questionableType={questionableType}
          onEdit={onFetchGroup}
          onAddNew={() => {
            setMode(QuestionsModuleMode.CREATE)
            setEditabLeQuestion(null)
          }}
          courseId={courseId}
        />
      </>
    )
  } else {
    return <></>
  }
}

import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import { CoursesPermissions } from '../lib/types'

export enum OnlineTagsSortBy {
  ID = 'id',
  NAME = 'name',
}

export interface OnlineTagRequest {
  name: string
}

export interface OnlineTagItem extends OnlineTagRequest {
  id: number
  coursesCount: number
}

export namespace GetOnlineTagsDTO {
  export interface Request extends PaginationRequest {
    sortBy?: OnlineTagsSortBy
    permission:
      | CoursesPermissions.VIEW_COURSE_KEYWORDS
      | CoursesPermissions.ADD_EDIT_COURSE
  }

  export interface Response extends PaginatedBaseResponse {
    online_tags: OnlineTagItem[]
  }
}

export namespace CreateOnlineTagDTO {
  export interface Request extends OnlineTagRequest {}
  export type Response = any
}

export namespace EditOnlineTagDTO {
  export interface Request extends OnlineTagRequest {}
  export type Response = any
}

export type OnlineTagInitialValues = {
  id?: number
  name: string
}

export type OnlineTagModalSubmitedValues = {
  id?: number
  name: string
}

export namespace GetOnlineTagsDTO {
  export interface Request extends PaginationRequest {}
  export interface Response {
    count: number
    online_tags: OnlineTagItem[]
    page: number
    pages: number
  }
}
